import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { profileServices } from '../services/profileServices';
import { photoServices } from '../services/photoServices';
import './Profiles.css';
import maleAvatar from '../assets/male-avatar.png';
import femaleAvatar from '../assets/female-avatar.png';

const Profiles = () => {
    const [profiles, setProfiles] = useState([]);
    const [profilePhotos, setProfilePhotos] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        loadHomepageProfiles();
    }, []);

    const loadHomepageProfiles = async () => {
        const data = await profileServices.getHomepageProfiles();
        setProfiles(data);
        // Fetch photos for each profile
        data.forEach(async (profile) => {
            try {
                const photos = await photoServices.getAllPhotos(profile.user_id);
                if (photos && photos.length > 0) {
                    setProfilePhotos(prev => ({
                        ...prev,
                        [profile.user_id]: `data:image/jpeg;base64,${photos[0].image_data}`
                    }));
                }
            } catch (error) {
                console.log(`No photos found for user ${profile.user_id}`);
            }
        });
    };

    const getProfileImage = (profile) => {
        if (profilePhotos[profile.user_id]) {
            return profilePhotos[profile.user_id];
        }
        return profile.gender?.toLowerCase() === 'female' ? femaleAvatar : maleAvatar;
    };

    const handleViewProfile = (profileId) => {
        navigate(`/userprofile/${profileId}`);
    };

    return (
        <div className="profiles-container">
            <h2 className="profiles-title">Featured Profiles</h2>
            <div className="profiles-grid">
                {profiles.map((profile) => (
                    <div key={profile.profile_id} className="profile-card">
                        <div className="profile-image">
                            <img 
                                src={getProfileImage(profile)} 
                                alt={`${profile.gender} profile`}
                                className="avatar-image"
                            />
                        </div>
                        <h3 className="profile-name">{profile.name || 'Anonymous'}</h3>
                        <div className="profile-details">
                            <p className="profile-info"><span>Age:</span> {profile.age || 'N/A'}</p>
                            <p className="profile-info"><span>Gender:</span> {profile.gender}</p>
                            <p className="profile-info"><span>Religion:</span> {profile.religion || 'N/A'}</p>
                        </div>
                        <button 
                            className="view-profile-btn"
                            onClick={() => handleViewProfile(profile.user_id)}
                        >
                            View Profile
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Profiles;